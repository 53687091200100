import css from "./CreationGuide.module.scss";
import ListTitle from "@/shared/textes/ListTitle/ListTitle";
import { useTranslation } from 'i18n'
import Image from "next/image";
import Link from "next/link";
import LinksProvider from "@/services/http/LinksProvider";
import Button from "@/shared/button/simpleButton/Button";
import ExpandedText from "@/shared/ExpandedText";

const HomeCreationGuide = () => {
    const { t } = useTranslation(['common']);

    return (
        <div className={css.container}>

            <div className={css.guide}>
                <Image
                    style={{ borderRadius: "6px" }}
                    src={"/static/images/home/guide/guide.webp"}
                    height={360}
                    width={480}
                />
                <div className={css.guide__content}>
                    <ListTitle
                        customClassTitle={css.guide__content__title}
                        title={t('home:guide.title')}
                        subtitle={t('home:guide.subtitle')}
                        customClassSubtitle={css.guide__content__subtitle}
                    />
                    <ExpandedText className={css.guide__content__text} text={t('home:guide.content')} maxLength={265} />
                    <div className={css.btn_container}>
                        <Link href={LinksProvider.get(LinksProvider.ROUTES.HOW_IT_WORKS)}>
                            <a>
                                <Button className={css.btn_light}>
                                    {t('home:guide.ccm')}
                                </Button>
                            </a>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default HomeCreationGuide;