import React from "react";
import { withTranslation } from "../../../../i18n";
import ListTitle from "../../../../shared/textes/ListTitle/ListTitle";
import Link from "next/link";
import css from "./HomeBlog.module.scss";
import LinksProvider from "@/services/http/LinksProvider";
import PublicationsService from "@/services/domain/PublicationsService";
import StringService from "@/services/utils/StringService";
import Image from "next/image";
import parse from 'react-render-html';

const namespacesRequired = ["home"];

const HomeBlog = ({ publications, t }) => {
  return (
    <div className={css.blog}>
      {publications && publications.length && (
        <React.Fragment>
          <ListTitle
            title={t("home:blog.title")}
            subtitle={parse(t("home:blog.subtitle"))}
            customClassSubtitle={css.subtitle}
            customClassTitle={css.title}
          />
          <div className={css.articles}>
            {publications.map((publication, index) => (
              <Link
                key={index}
                href={PublicationsService.getPublicationLink(publication, t)}
              >
                <a className={css.card}>
                  <div
                   className={css.card__image}
                  >
                    <Image
                      objectFit="cover"
                      objectPosition="center"
                      src={publication.cover_photo.url}
                      alt={`pub${index}_url`}
                      layout="fill"
                      sizes='100vw'
                    />
                  </div>
                  <span>
                    <h2>{StringService.capitalizeFirst(publication.title)}</h2>
                    <p>
                      {t(
                        "publications:publication.category-" +
                          publication.category
                      )}
                    </p>
                  </span>
                </a>
              </Link>
            ))}
          </div>
          <div className={css.btn_container}>
            <Link
              href={LinksProvider.get(
                LinksProvider.ROUTES.PUBLICATION.ALL_NO_PARAMS
              )}
            >
              <a className={css.blog__btn_light}>
                <span>{t("home:blog.more-articles")}</span>
              </a>
            </Link>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withTranslation(namespacesRequired)(HomeBlog);
